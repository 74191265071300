<template>
  <div class="container-fluid mt-5">
    <div>
      <card class="no-bdelivery-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ stockMovement.code }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editStockMovement()"
                  v-if="stockMovement.status === STATUS_DRAFT"
                  :title="$t('COMMON.EDIT')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    stockMovement.status === STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_STOCK_MOVEMENTS)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteStockMovement()"
                  :title="$t('COMMON.DELETE')"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      printModalOpened = true;
                    }
                  "
                  v-if="!!stockMovement.id"
                  :disabled="loading"
                  :title="$t('COMMON.EXPORT_EXCEL')"
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-file-excel"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="validateStockMovement()"
                  :disabled="loading"
                  v-if="
                    stockMovement.items.length > 0 &&
                    stockMovement.status === STATUS_DRAFT
                  "
                  :title="$t('COMMON.STOCK_MOVEMENTS.VALIDATE')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                    {{ $t("STOCK_MOVEMENTS.VALIDATE") }}
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="sm"
                  @click="cancelStockMovement()"
                  :disabled="loading"
                  v-if="stockMovement.status === STATUS_VALIDATED"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    {{ $t("STOCK_MOVEMENTS.CANCEL") }}
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack()" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'stock-movements'"
                :objectId="stockMovement.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="details"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="false">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <stock-movement-view-global :stockMovement="stockMovement" />
                </tab-pane>

                <tab-pane title="details" id="2" :active="true">
                  <span slot="title">
                    <i class="ni ni-bullet-list-67" />
                    {{ $t("COMMON.DETAILS") }}
                  </span>
                  <stock-movement-view-details
                    :stockMovement="stockMovement"
                    @stockMovementItemsUpdated="() => {valueRenderKey++;}"
                  />
                </tab-pane>
                <tab-pane
                  title="stock-movement-value"
                  id="3"
                >
                  <span slot="title">
                    <i class="fa fa-money-check" />
                    {{ $t("WAREHOUSES.VALUE") }}
                  </span>
                  <stock-movement-view-value
                    :key="valueRenderKey"
                    :stockMovement="stockMovement"
                  />
                </tab-pane>
                <tab-pane
                  title="files"
                  id="998"
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
                    stockMovement.organization
                  "
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="stockMovement" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <stock-movement-view-logs :stockMovement="stockMovement" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <modal :show.sync="printModalOpened" modal-classes="modal-secondary" size="xl">
      <div>
        <h2>
          {{ $t("COMMON.EXPORT_EXCEL") }}
        </h2>
        <div>
          <base-input
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
            :label="`${$t('COMMON.SUPPLIERS')}`"
            :placeholder="$t('COMMON.SUPPLIERS')"
          >
            <suppliers-selector
              :suppliers="[]"
              :filterable="true"
              :showAll="false"
              :organization="stockMovement.organization.id"
              @suppliersChanged="
                (suppliers) => {
                  selectedSuppliers = suppliers.map((item) => item.id);
                }
              "
            />
          </base-input>
          <base-input
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CATEGORIES)"
            :label="`${$t('COMMON.CATEGORIES')}`"
            :placeholder="$t('COMMON.CATEGORIES')"
          >
            <categories-selector
              :categories="[]"
              :filterable="true"
              :showAll="false"
              :organization="stockMovement.organization.id"
              @categoriesChanged="
                (categories) => {
                  selectedCategories = categories.map((item) => item.id);
                }
              "
            />
          </base-input>

          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              @click="print()"
            >
              {{ $t("STOCK_MOVEMENTS.PRINT") }}
            </base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  STATUS_DRAFT,
  STATUS_CANCELED,
  STATUS_VALIDATED,
} from "@/constants/stockMovements";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import StockMovementViewGlobal from "./partials/StockMovementViewGlobal.vue";
import StockMovementViewDetails from "./partials/StockMovementViewDetails.vue";
import StockMovementViewLogs from "./partials/StockMovementViewLogs.vue";
import defaultStockMovement from "./defaultStockMovement";
import SuppliersSelector from "@/components/SuppliersSelector.vue";
import CategoriesSelector from "@/components/CategoriesSelector.vue";
import StockMovementViewValue from "./partials/StockMovementViewValue.vue";
export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    StockMovementViewGlobal,
    StockMovementViewDetails,
    StockMovementViewLogs,
    ListFileComponent,
    NotificationSubscription,
    SuppliersSelector,
    CategoriesSelector,
    StockMovementViewValue,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      loading: false,
      stockMovement: cloneDeep(defaultStockMovement),
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_VALIDATED: STATUS_VALIDATED,
      STATUS_CANCELED: STATUS_CANCELED,
      printModalOpened: false,
      selectedSuppliers: [],
      selectedCategories: [],
      valueRenderKey: 0,
    };
  },

  computed: {},

  watch: {},

  async created() {
    await this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("stockMovements/get", id);
        this.stockMovement = this.$store.getters["stockMovements/stockMovement"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editStockMovement(row) {
      this.$router.push({
        name: "Edit StockMovement",
        params: { id: this.stockMovement.id },
      });
    },

    async validateStockMovement() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        this.loading = true;
        await this.$store.dispatch("stockMovements/validate", this.stockMovement.id);
        this.$notify({
          type: "success",
          message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_VALIDATED"),
        });
        this.loading = false;
        await this.get();
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        swal.close();
      }
    },

    async cancelStockMovement() {
      const confirmation = await swal.fire({
        title: this.$t("STOCK_MOVEMENTS.CANCEL_STOCK_MOVEMENT_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        this.loading = true;
        await this.$store.dispatch("stockMovements/cancel", this.stockMovement.id);
        this.$notify({
          type: "success",
          message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_CANCELED"),
        });
        this.loading = false;
        await this.get();
        swal.close();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        swal.close();
      }
    },

    async deleteStockMovement() {
      const confirmation = await swal.fire({
        title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("stockMovements/destroy", this.stockMovement.id);
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("STOCK_MOVEMENTS.STOCK_MOVEMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const response = await this.$store.dispatch("stockMovements/print", {
          id: this.stockMovement.id,
          filters: {
            suppliers: this.selectedSuppliers,
            categories: this.selectedCategories,
          },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `stock-movement-${this.stockMovement.id}.xlsx`);
        document.body.appendChild(link);
        link.click();

        this.selectedSuppliers = [];
        this.printModalOpened = false;
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        swal.close();
      }
    },

    async viewPurchasesOrder() {
      this.$router.push(this.$objectViewRoute(this.stockMovement.purchasesOrder));
    },

    goBack() {
      this.$router.push({ name: "List StockMovements" });
    },
  },
};
</script>
