<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12"
          >{{ $t("WAREHOUSES.VALUE") }}

          <i class="fas fa-spinner fa-spin" v-if="loading" />
        </span>
      </div>
    </div>

    <div>
      <el-table
        class="warehouse-list-table table-responsive bg-grey text-white align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="suppliersPrices"
        :summary-method="getSummaries"
        show-summary
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.SUPPLIER')"
          prop="name"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <a href="#" @click="openSupplier(row.supplierId)">
              {{ row.supplierName }}
            </a>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.TOTAL')"
          prop="amount"
          min-width="250px"
          class-name="number-column"
          label-class-name="number-column"
        >
          <template v-slot="{ row }">
            {{ $formatCurrency(row.amount) }}
          </template>
        </el-table-column>

        <el-table-column
          :label="'%'"
          prop="percentage"
          min-width="250px"
          class-name="number-column"
          label-class-name="number-column"
        >
          <template v-slot="{ row }">
            {{ row.percentage.toFixed(2) }} %
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { Button, Tooltip, Table, TableColumn } from "element-ui";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [],

  props: {
    stockMovement: {
      type: Object,
    },
  },

  data() {
    return {
      suppliersPrices: [],
      loading: true,
    };
  },

  computed: {},

  methods: {
    async getValue() {
      this.loading = true;
      const value = await this.$store.dispatch("stockMovements/value", {
        id: this.stockMovement.id,
      });
      this.suppliersPrices = Object.values(value);
      this.loading = false;
    },

    getSummaries(param) {
      const totalAmount = this.suppliersPrices.reduce(
        (total, item) => total + item.amount,
        0
      );
      const totalPercentage = this.suppliersPrices.reduce(
        (total, item) => total + item.percentage,
        0
      );
      const sums = [
        this.$t("COMMON.TOTAL"),
        this.$formatCurrency(totalAmount),
        `${totalPercentage.toFixed(2)} %`,
      ];

      return sums;
    },

    openSupplier(supplierId) {
      this.$emit("onOpenSupplier", supplierId);
    },
  },

  created() {
    this.getValue();
  },

  watch: {},
};
</script>
